<template>
    <ModuleWrapper 
        :pageTitle="pageTitle"
        :bodyPadding="$route.name === 'projects-gant' ? false : true"
        :headerBg="!isMobile"
        :pageRoutes="routes">
        <template v-if="!isMobile" v-slot:h_left>
            <PageFilter
                :model="initPageModel"
                :key="initPageName"
                :excludeFields="excludeFields"
                size="large"
                :page_name="initPageName" />
        </template>
        <template v-if="!isMobile" v-slot:h_right>
            <template v-if="isSprints">
                <a-button 
                    v-if="showCreateButton"
                    class="header__button"
                    icon="plus" 
                    size="large"
                    type="primary"
                    @click="addSprint()">
                    {{ $t('project.add_sprint') }}
                </a-button>
            </template>
            <template v-else>
                <a-button 
                    v-if="addTemplateButton && addButton"
                    class="header__button"
                    icon="plus" 
                    size="large"
                    type="primary"
                    ghost
                    @click="createTemplateHandler()" >
                    {{ addTemplateButton.label ? addTemplateButton.label : $t(this.templateButtonText) }}
                </a-button>
                <a-button 
                    v-if="addButton"
                    class="header__button"
                    icon="plus" 
                    size="large"
                    type="primary"
                    @click="createHandler()" >
                    {{ addButton.label ? addButton.label : $t(this.buttonText) }}
                </a-button>
                <component
                    v-if="!isGant"
                    :is="settingsButtonWidget"   
                    :pageName="initPageName"
                    class="ml-2" />
            </template>
        </template>
        <router-view 
            :tableType="tableType"
            :pageModel="pageModel"
            :page_name="initPageName"
            :buttonSize="buttonSize"
            :routeInfo="getRouteInfo"
            :buttonText="buttonText"
            :templateButtonText="templateButtonText"
            :pageConfig="pageConfig" />
    </ModuleWrapper>
</template>

<script>
import SettingsButton from '@/components/TableWidgets/SettingsButton'
import PageFilter from '@/components/PageFilter'
import ModuleWrapper from '@/components/ModuleWrapper/index.vue'
import routes from './config/router.js'
import eventBus from '@/utils/eventBus'
export default {
    components: {
        PageFilter,
        SettingsButton,
        ModuleWrapper
    },
    props: {
        tableType: {
            type: String,
            default: 'projects'
        },
        pageModel: {
            type: String,
            default: 'workgroups.WorkgroupModel'
        },
        page_name: {
            type: String,
            default: 'page_list_projects.WorkgroupModel'
        },
        buttonSize: {
            type: String,
            default: 'large'
        },
        buttonText: {
            type: String,
            default: 'project.add_project'
        },
        templateButtonText: {
            type: String,
            default: 'project.templates'
        },
        pageConfig: {
            type: Object,
            default: () => null
        }
    },
    computed: {
        showCreateButton() {
            return this.getRouteInfo?.pageActions?.add
        },
        isSprints() {
            return this.$route.name === 'projects-sprints' ? true : false
        },
        isGant() {
            return this.$route.name === 'projects-gant' ? true : false
        },
        initPageModel() {
            if(this.$route.name === 'projects-sprints')
                return 'tasks.TaskSprintModel'
            return this.pageModel
        },
        initPageName() {
            if(this.$route.name === 'projects-gant')
                return this.page_name + '_gant'
            if(this.$route.name === 'projects-sprints')
                return this.page_name + '_sprints'
            return this.page_name
        },
        excludeFieldsKey() {
            return this.excludeFields.length ? 'true' : 'false'
        },
        excludeFields() {
            if(this.$route.name === 'projects-gant')
                return ['dead_line', 'dead_line__exclude']
            return []
        },
        currentRoute() {
            if(this.$route.name === 'projects-gant' || this.$route.name === 'projects-list')
                return 'projects'
            return this.$route.name
        },
        pageTitle() {
            return this.$route?.meta?.title || ''
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        settingsButtonWidget() {
            return () => import(/* webpackMode: "lazy" */'@/components/TableWidgets/SettingsButton')
        },
        createButton() {
            return this.pageConfig?.headerButtons?.createButton || null
        },
        getRouteInfo() {
            return this.$store.getters['navigation/getRouteInfo'](this.currentRoute)
        },  
        addButton() {
            if(this.getRouteInfo?.pageActions?.add) 
                return {
                    label: this.getRouteInfo?.buttonConfig?.label || this.$t(this.buttonText)
                }
            return null
        },
        addTemplateButton() {
            if(this.getRouteInfo?.pageActions?.addTemplate) 
                return {
                    label: this.getRouteInfo?.templateButtonConfig?.label || this.$t(this.templateButtonText)
                }
            return null
        }
    },
    data() {
        return {
            routes
        }
    },
    methods: {
        addSprint() {
            eventBus.$emit('add_sprint')
        },
        createTemplateHandler() {
            this.$router.replace({
                query: { createProjectTemplate: true }
            })
        },
        testCreateHandler() {
            this.$router.replace({
                query: { createProject: true }
            })
        },
        createHandler() {
            this.$router.replace({
                query: { create_project: true }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.header__button + .header__button {
    margin-left: 10px;
}
</style>